<template>
  <div>
    <div :class="`d-flex flex-column ${$isMobile() ? 'm-10' : 'mx-40 my-20'}`">
      <p class="center font-bold font-30">Terms &amp; Conditions</p>
      <p class="mt-10">
        Payment in Full required (labor Only) to Secure Date & Time UNLESS Pre-paid through a 3rd party.
        <br/>
        <b>Self-Move Customers (Uhaul, Rental Truck, POD, private)</b>
        <br/>
        Be sure to have cargo insurance BEFORE you drive! Call your insurance agent/broker. Get your life's contents protected before hitting the road!
        <br />
        Need: Driving Services,
        <br/>
        Yes We Can! ASK us how we can help.
        <br/>
        Customers are responsible for their own materials - Pads, TV boxes, shrink wrap and more
      </p>
      <span>
        MOVX may offer, at a prescribed rate, packing and loading materials including but not limited to:
      </span>
      <ul class="mt-1 ml-5">
        <li>
          Approved moving pads and/or blankets
        </li>
        <li>
          Approved storage/ long term- disposable pads
        </li>
        <li>
          Shrink Wrap and Tape
        </li>
        <li>
          Other packing supplies including, but not limited to, proper box materials for packing service (Supplies limited during high volume)
        </li>
      </ul>
      <p>
        These conditions explain the rights, obligations, and responsibilities of all parties to this Agreement.
        Where we use the word “Customer” it means you. Where we use the term “the Company” this means MOVX MOVING COMPANY LLC.
        The terms and conditions of the Terms of Service can be varied or amended subject to prior written agreement.
      </p>

      <p class="mt-5 font-bold font-25">1. QUOTES</p>
      <p class="font-bold font-20">1.1 WHAT IS INCLUDED</p>
      <span>
        Included in each quote is: The time and date requested to complete your move, the duration of the move, the total number of crew members that are assigned,
        and the addresses the crew will be working.
      </span>
      <p class="mt-5 font-bold font-20">1.2 WHAT IS NOT INCLUDED IN OUR QUOTES</p>
      <span>
        Our quote, unless otherwise stated, does not include, cancellation/ postponement waivers, or any fees or taxes payable to government bodies or agencies.
        24hr cancellation policy.
      </span>
      <p class="mt-5 font-bold font-15">1.2.1 WORK NOT INCLUDED</p>
      <span>
        The following is not included in the Company's Quotation, unless agreed by the Company in writing prior to the beginning of work:
      </span>
      <ul class="mt-1 ml-5">
        <li>
          Dismantle or assemble furniture of any kind; with the exception of certain beds, baby cribs or kitchen tables.
        </li>
        <li>
          Disconnect, re-connect, dismantle or re-assemble appliances, fixtures, fittings or equipment.
        </li>
        <li>
          Zero liability for tvs not in a box.
        </li>
        <li>
          Take up or lay fitted floor coverings.
        </li>
        <li>
          Move or store any items excluded under Clause Excluded Items.
        </li>
        <li>
          Dismantle, move or assemble garden furniture and equipment including, but not limited to: sheds, greenhouses, garden shelters, outdoor play equipment,
          satellite dishes, move paving slabs, planters and the like.
        </li>
      </ul>
      <p class="mt-5 font-bold font-15">1.2.2 ADDITIONAL CHARGES</p>
      <span>
        Unless already included in the Company Quotation, reasonable additional charges will apply in the following circumstances:
      </span>
      <ul class="mt-1 ml-5">
        <li>
          The moving of grand pianos, Safes, weight lifting equipment, or like large or extra heavy items.
        </li>
      </ul>

      <p class="mt-5 font-bold font-25">2. YOUR RESPONSIBILITY</p>
      <p class="font-bold font-20">2.1 BEFORE YOU SCHEDULE</p>
      <p class="font-bold font-15">2.1.1 YOU MUST HAVE LEGAL RIGHTS TO THE GOODS BEING MOVED</p>
      <span>
        By entering into this Agreement, you guarantee that:
      </span>
      <span>
        The goods to be moved are property, the goods are Your property free of any legal charge;
        or You have the full authority of the owner or anyone having a legal interest in them to enter into this Agreement and
        You have made the owner fully aware of these terms and conditions prior to entering into this Agreement and that they have agreed to them.
      </span>
      <span>
        If at any time following the effective date of this Agreement another person has or obtains an interest in the goods Customer will advise Company of their name,
        telephone number and address in writing immediately.
        The new owner shall be made part to this Agreement. Customer agrees to fully indemnify and hold harmless the Company its members and its representatives from any damages,
        loss, cost or liability including legal fees and the cost of enforcing this indemnity arising out of o resulting from any breach of this Section 2.1.1.
      </span>
      <span>
        Agreement with the Customer will remain in force until the Company has received an executed Agreement from the third-party.
        This Agreement may only be assigned to a third-party with the prior written consent of the Company.
        If assigned, the Company will need the name, phone number and address of the assignee. The assignee shall become party to a new agreement of terms and service.
      </span>
      <p class="mt-5 font-bold font-15">2.1.2 GOODS COMPANY WILL NOT MOVE OR STORE FOR CUSTOMER</p>
      <span>
        Unless previously agreed in writing by an authorized Company representative, the following items must not be submitted for moving and
        will under no circumstances be moved or stored by Company.
        The items listed below may present risks to health and safety and risk of fire.
        The Customer should make your own arrangements for the transport and storage of the following:
      </span>
      <ul class="mt-1 ml-5">
        <li>
          Potentially dangerous, damaging or explosive items, including gas bottles, aerosols, paints, firearms or ammunition.
        </li>
        <li>
          Jewelry, watches, trinkets, precious stones or metals, money, deeds, securities, mobile telephones, portable media and computing devices, stamps, coins, or goods of any similar kind.
        </li>
        <li>
          Goods (Customer belongings) likely to encourage vermin or other pests or to cause infestation or contamination.
          Company shall notify you in writing as soon as practicable if any of the Goods, are in our opinion hazardous to health,
          dirty or unhygienic or likely to attract vermin or pests and under what conditions we would be prepared to accept such Goods or whether we refuse to accept them.
          Should Company refuse to accept the goods we will have no liability to Customer.
        </li>
        <li>
          Perishable items and/or those requiring a controlled environment.
        </li>
        <li>
          Any animals, birds, fish, reptiles or plants. Goods which require special license or government permission for export or import.
        </li>
        <li>
          Under no circumstances will Prohibited or stolen goods, drugs or pornographic material be moved or stored by Company.
        </li>
        <li>
          If Customer submits such goods without Company knowledge the Company will make them available for Customer collection and
          if Customer does not collect them within a reasonable time the Company may apply for a court order to dispose of any such goods found.
          Customer agrees to pay the Company any charges, expenses, damages, legal costs or penalties reasonably incurred by the Company in disposing of the goods.
        </li>
      </ul>
      <p class="mt-5 font-bold font-15">2.1.3 VALUATION SELECTION AND WAIVER</p>
      <b>
        A LIMITED LIABILITY SERVICE WAIVER & RELEASE AGREEMENT - PLEASE READ CAREFULLY MOVING LABOR ONLY SERVICES
      </b>
      <span>
        Property Insurance Qualifications: The Following is a list of General Points. Please ask any and all questions prior.
      </span>
      <span>
        There is NO FEDERAL Law or Regulation mandating a Minimum Property Insurance Coverage for Moving Labor Services.
      </span>
      <span>
        MOVX MOVING COMPANY LLC recommends that all customers acquire an independent, 3rd party, property insurance policy/rider
        (please refer to your homeowner's or renter's insurance agent/broker) for any and all items that customer(s) deem valuable, irreplaceable, expensive and/or otherwise.
      </span>
      <ul class="mt-1 ml-5">
        <li>
          MOVX MOVING COMPANY LLC is NOT an insurance agent, broker, or provider and does not promote or support any insurance agency.
          When MOVX MOVING COMPANY LLC and its' affiliates' provide Moving Labor Only Service(s) (of household, commercial or other goods to be moved)
          it is the responsibility by of the customer and/or client to provide: a safe working environment, the proper equipment and materials.
          <br/>
          Any damage(s) occurring by MOVX MOVING COMPANY LLC during the course of a load and/ or unload only are to be addressed with the a team lead and
          a Customer Damage Claim completed before the completion of the move.
          Any and all Customer Damage Claim(s) that are not witnessed by a MOVX MOVING COMPANY LLC team member will not be validated.
          During the process of loading and unloading MovX under certain circumstances will cover items damaged in our hands per pound limited to .60 cents.
        </li>
        <li>
          MOVX MOVING COMPANY LLC is not responsible for damages occurring during transit.
          This is called Cargo Coverage and is required in Full Service Moves by Federal Law and follows the vehicle, the cargo, and the party responsible for the vehicle (own or rent).
        </li>
      </ul>
      <p class="mt-5 font-bold font-15">2.1.4 CHARGES FOR SPECIALTY ITEMS</p>
      <span>
        It is the Customer's responsibility for paying additional charges for Pool tables, pianos, treadmills, elliptical, riding lawn mowers, safes, gun safes, hot tubs, motorcycles,
        and other items that may be added to this list upon assessment of Company.
        You will be charged a “specialty item” charge. It will be in addition to the hourly charge of the move.
      </span>
      <p class="mt-5 font-bold font-15">2.1.5 SCHEDULING</p>
      <span>
        After receiving a quote, the Customer is responsible for notifying the Company of the date of which you intend to use the services.
        You are responsible for notifying MOVX MOVING COMPANY LLC of any date changes, postponements, or cancelations at least 24 hours in advance of the original scheduled date of service.
      </span>
      <p class="mt-5 font-bold">2.1.5.1 CHARGES IF YOU POSTPONE OR CANCEL YOUR REMOVAL</p>
      <span>
        A 24 hour notice is required in order to avoid a cancellation/rescheduling fee for moves scheduled.
        The cancellation fee is the loss of your reservation deposit or full labor only payment described below in Section 2.1.6.
        A rescheduling fee may be required, if normal moving resources are not available that day and create a condition where Company has to rent or
        hire additional moving resources to accommodate Customer's rescheduled move date.
      </span>
      <span>
        Payment can be made in Zelle, Venmo, cash, Certified Check, US Postal Money Order, Credit/Debit Cards.
        Credit or Debit cards may <u>incur 4% surcharge</u> at the discretion of the Company.
        There will be a $50 charge for any returned check.
      </span>
      <p class="mt-5 font-bold font-15">2.1.6 RESERVATION DEPOSIT</p>
      <span>
        Customer is responsible for paying a reservation deposit (if not booked online in full) before you schedule your move.
        This deposit must be paid in full and at the time of booking.
        No reservations are made on the Company's booking calendar without a fully paid reservation deposit.
      </span>
      <p class="mt-5 font-bold font-20">2.2 BEFORE YOU MOVE</p>
      <p class="font-bold font-15">2.2.1 NOTIFICATION OF DELAYS & UNFORESEEN CIRCUMSTANCES</p>
      <p class="font-bold">2.2.1.1 SELF-PACKING</p>
      <span>
        If Customer elects to waive having the Company pack your items, you are responsible for properly packing and crating your items.
        Customer is responsible for completing the packing by the date of your scheduled service.
        Any additional packing by the Company that is necessary to properly complete the move may be reflected in additional charged hours to Customer's final bill.
        Additional hourly charges may be applied if the Company's moving crew is prevented from working while the Customer completes packing of Customer goods.
      </span>
      <p class="mt-5 font-bold font-20">2.3 AFTER YOUR MOVE</p>
      <p class="font-bold font-15">2.3.1 FINAL PAYMENT</p>
      <span>
        Unless otherwise agreed by the Company in writing, payment is required in full by cleared funds, cash or credit card, at the end of the moving period.
      </span>

      <p class="mt-5 font-bold font-25">3. OUR LIABILITY FOR LOSS OR DAMAGE</p>
      <p class="font-bold font-20">3.1 RIGHT OF REFUSAL</p>
      <span>
        We reserve the right not to service you under conditions that, at our sole discretion, we consider dangerous, unsanitary,
        abusive or contained outside the scope of household moving.
        In the event we exercise this right, we will not be liable to you or any other entity for direct or consequential damages.
        Customer forfeits their deposit and will be charged a minimum of one hour of labor, at the Companies discretion.
      </span>
      <p class="mt-5 font-bold font-20">3.2 DAMAGE TO GOODS</p>
      <p class="font-bold font-15">3.2.1 RIGHT TO REPAIR</p>
      <span>
        The Company reserves the right to repair any damaged item.
      </span>
      <p class="mt-5 font-bold font-15">3.2.2 VALUATION COVERAGE</p>
      <p class="font-bold">3.2.2.1 EXCLUDED ITEMS - WHAT IS NOT COVERED</p>
      <span>
        Understanding the exclusions list will help you prepare better for your move:
      </span>
      <ul class="mt-1 ml-5">
        <li>
          Any items that were packed by Customer or Customer Agent and not by Company movers.
        </li>
        <li>
          Any furniture items when furniture pads are not used for protection; based on Customer refusal for Company to use them.
        </li>
        <li>
          WE DO NOT LOAD TVS WITHOUT A BOX. (Options If no box, we can blanket tvs and load them in a personal vehicle, with permission any tvs or electronics moved
          without a box into a vehicle/ moving van we can take all cautions to protect your item/data blanketing wrapping and loading between mattress's but in the case of
          loss of item/data we cannot cover the liability) We can also supply a tv box with an extra charge.
        </li>
        <li>
          Special care items, including glass tops and mirrors or high value items such as digital TV sets, without proper packing and preparation.
        </li>
        <li>
          We will not be held responsible for any kind of planters/pots made of clay, porcelain, ceramic or glass.
        </li>
      </ul>
      <p class="font-bold">3.2.2.2 IF WE PACK - WHAT IS COVERED</p>
      <span>
        Despite the long list of exclusions and limitations; we do cover items that we knowingly or without dispute damage.
        Released Valuation Coverage is provided on these items or all items dropped or damaged on-site while in the possession of our movers,
        assuming they do not meet any of the Items not excluded in Clauses 2.1, 2.1.3 and 3.2.2.1.
      </span>
      <p class="mt-5 font-bold">3.2.2.3 IF YOU PACK</p>
      <span>
        Property Insurance Qualifications: The Following is a list of General Points.
        Please ask any and all questions prior.
        There is NO FEDERAL Law or Regulation mandating a Minimum Property Insurance Coverage for Moving Labor Services.
        MOVX MOVING COMPANY LLC recommends that all customers acquire an independent, 3rd party, property insurance policy/rider
        (please refer to your homeowner's or renter's insurance agent/broker) for any and all items that customer(s) deem valuable, irreplaceable, expensive and/or otherwise
      </span>
      <ul class="mt-1 ml-5">
        <li>
          MOVX MOVING COMPANY LLC is NOT an insurance agent, broker, or provider and does not promote or support any insurance agency.
          <br/>
          When MOVX MOVING COMPANY LLC and its' affiliates' provide Moving Labor Only Service(s) (of household, commercial or other goods to be moved)
          it is the responsibility of the customer and/or client to provide: a safe working environment, the proper equipment and materials.
          <br/>
          Any damage(s) occurring by MOVX MOVING COMPANY LLC during the course of a load and/ or unload only are to be addressed with the a team lead and
          a Customer Damage Claim completed before the completion of the move.
          Any and all Customer Damage Claim(s) that are not witnessed by a MOVX MOVING COMPANY LLC team member will not be validated.
        </li>
        <li>
          MOVX MOVING COMPANY LLC is not responsible for damages occurring during transit.
          This is called Cargo Coverage and is required in Full Service Moves by Federal Law and follows the vehicle, the cargo, and the party responsible for the vehicle (own or rent).
        </li>
      </ul>
      <p class="mt-5 font-bold">3.2.2.4 IF WE DRIVE</p>
      <span>
        What is covered: Despite the long list of exclusions and limitations; we do cover items that we knowingly or without dispute damage.
        Released Valuation Coverage is provided on all items dropped or damages on site while in the possession of our movers, assuming they do not meet any of the exclusions above.
      </span>
      <p class="mt-5 font-bold">3.2.2.5 IF YOU DRIVE</p>
      <span>
        MOVX MOVING COMPANY LLC is not responsible for damages occurring during transit.
        This is called Cargo Coverage and is required in Full Service Moves by Federal Law and follows the vehicle, the cargo, and the party responsible for the vehicle (own or rent).
      </span>
      <p class="mt-5 font-bold">3.2.2.6 TRUE LIMIT FOR CLAIMS</p>
      <span>
        If Customer or Customer's authorized representative collect the goods, the Company must be notified in writing of any loss or
        damage at the time the goods are handed to Customer or Customer agent or as soon as practically possible.
        For goods which the Company delivers, Customer must give Company detailed notice in writing of any loss and damage within seven days of delivery by Company.
        Company may agree to extend this time limit upon receipt of Customer written request provided such request is received within seven (7) days of delivery.
        Consent to such a request will not be unreasonably withheld.
      </span>
      <p class="mt-5 font-bold font-20">3.3 DAMAGE TO PREMISES OR PROPERTY OTHER THAN GOODS</p>
      <p class="font-bold font-15">3.4.2 DISASSEMBLY & INSTALLATION</p>
      <span>
        MOVX MOVING COMPANY LLC will move appliances, equipment, or machinery upon request.
        However, MOVX MOVING COMPANY LLC will not be held liable for electrical or water damage to property associated with any connection or
        disconnection of appliances, equipment, or machinery.
        <br/>
        We will not replace electrical cords on dryers.
        Refrigerator doors sometimes need to be removed in order to go through narrow hallways or doors.
        Company will not take off doors that have water or electrical connection going through them and Company will not remove freezer bottom drawers on refrigerators.
        <br/>
        If Customer knows that this needs to be done in order to move the refrigerator in or out of the area where it is located,
        please contact an appliance company to do that ahead of time.
        Under no circumstances will Company be involved in such tasks. Washers that are front loaders have locks to secure the drum during the move.
      </span>
      <p class="mt-5 font-bold font-15">3.4.3 PRIOR, EXISTING OR POST SERVICE DAMAGE</p>
      <span>
        Company is in no way responsible for damage to items that were preexisting or occurred prior to date of the service completion or after the date of the service completion.
      </span>
      <p class="mt-5 font-bold font-15">3.4.4 IMPROPERLY SELF-PACKED OR SELF-PREPARED ITEMS</p>
      <span>
        Company is not responsible for self-packed items damaged as a result of improper packing procedures and techniques.
      </span>
      <p class="mt-5 font-bold font-15">3.4.5 DAMAGE-PRONE GOODS</p>
      <span>
        Any kind of furniture that is made out of pressed or particle material will have very limited to no value as far as liability of the Company.
        We will not assemble or disassemble furniture made of this material.
        Should Customer choose to disassemble your furniture, Company will move the pieces and will not reassemble upon delivery.
      </span>
      <p class="mt-5 font-bold font-15">3.4.6 INCLEMENT WEATHER</p>
      <span>
        Company is not held liable for damages caused by inclement weather.
      </span>
      <p class="mt-5 font-bold font-15">3.4.7 VALUABLES</p>
      <span>
        Company will not move or be liable for any valuables such as documents, medicine, priceless items, jewelry, and currency.
        Company will not be responsible or liable for any firearms. Please remove any firearms prior to Customer move.
      </span>
      <p class="mt-5 font-bold font-15">3.4.8 DELAYS IN TRANSIT</p>
      <span>
        Company Right to Hold the Goods (lien) “Lien” is the legal right of the remover to hold goods until the Customer has paid all outstanding charges.
        Company shall have a right to withhold and ultimately dispose of some or all of the goods if Customer fails to pay the charges and any other payments
        due under this or any other Agreement.
        <br/>
        These include any charges that Company has paid out on Customer behalf.
        While Company holds the goods Customer will be liable to pay all storage charges and other costs (including legal costs) reasonably incurred by Company
        in recovering Company charges and applying Company right of lien. These terms and conditions shall continue to apply.
      </span>
      <p class="mt-5 font-bold font-15">3.4.9 ARRIVAL WINDOWS</p>
      <span>
        Company will give you an estimated window of arrival.
        Company will do everything to make sure that we arrive there within that window, but please remember that the window of arrival time is an estimate.
        If Company is running early or late, we will call Customer as soon as possible and let you know.
      </span>
      <p class="mt-5 font-bold font-20">3.5 DISPUTES</p>
      <span>
        In any arbitration or litigation, the prevailing party shall be entitled to recover from the losing Party reasonable attorney's fees and other costs and
        expenses of the arbitration or litigation as awardee by the Court.
        The arbitrator will be selected by Company. The parties each waive their right to trial by jury.
      </span>
      <p class="mt-5 font-bold font-20">3.6 ROUTE AND METHOD</p>
      <span>
        Company has the right to choose the method and route by which to carry out the work and the location in respect of storage.
        Unless it has been specifically agreed otherwise in writing in the Company Quotation, other space/volume/capacity on Company vehicles and/or
        the container may be utilized for consignments of other customers.
      </span>
      <p class="mt-5 font-bold font-20">3.7 APPLICABLE LAW</p>
      <span>
        This Agreement is governed by and shall be construed in accordance with the laws of the State of Texas, without regards to its conflicts-of-laws principals.
      </span>
    </div>
    <div class="my-10">&nbsp;</div>
    <!-- Footer -->
    <KTFooterExtended></KTFooterExtended>
  </div>
</template>

<script>
import KTFooterExtended from "@/view/layout/footer/FooterExtended.vue";

export default {
  name: "Terms",
  components: {
    KTFooterExtended
  }
};
</script>